export const generatePostPath = (title: string) =>
  title
    .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
    .toLowerCase()
    .split(" ")
    .slice(0, 10)
    .join("-");

export const getGridStyles = (isMobileOrTablet: boolean) => {
  const commonStyles = {
    display: "grid",
    gridTemplateRows: "max-content",
    gridAutoRows: "1fr"
  };

  return isMobileOrTablet
    ? {
        ...commonStyles,
        paddingLeft: "5%",
        paddingRight: "5%",
        gridTemplateColumns: "100%"
      }
    : {
        ...commonStyles,
        paddingLeft: "15%",
        paddingRight: "15%",
        gridTemplateColumns: "30% 30% 30%",
        gap: "5%"
      };
};
