import { graphql, useStaticQuery } from "gatsby";
import Image from "gatsby-image";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { Page } from "../components/Page";
import { ContentfulListResponse } from "../utils/queryTypes";
import { getGridStyles } from "../utils/utils";

interface GalleryImage {
  id: string;
  image: {
    fluid: {
      src: string;
      srcSet: string;
      srcSetWebp: string;
      srcWebp: string;
      aspectRatio: number;
      sizes: string;
    };
  };
}

export default () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const images = useStaticQuery<
    ContentfulListResponse<"allContentfulGalleryImages", GalleryImage>
  >(graphql`
    query {
      allContentfulGalleryImages {
        edges {
          node {
            id
            image {
              fluid(quality: 50, maxWidth: 1000) {
                src
                srcSet
                srcWebp
                srcSetWebp
                aspectRatio
                sizes
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Page>
      <div style={getGridStyles(isTabletOrMobile)}>
        {images.allContentfulGalleryImages.edges.map((it) => (
          <Image
            fluid={it.node.image.fluid}
            style={{ boxShadow: "1px 2px 5px" }}
          />
        ))}
      </div>
    </Page>
  );
};
